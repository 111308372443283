import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAppHero, LazyAppHeroSingleImage, LazyArticleCard, LazyArticleGrid, LazyArticleShare, LazyArticles, LazyBlockquote, LazyBlockquoteModule, LazyCTA, LazyCTADropdown, LazyCTAGroup, LazyCallout, LazyCard, LazyCardGrid, LazyColumnLayout, LazyCompetitorComparisonChart, LazyCookiesList, LazyCustomHeading, LazyCustomInlineRTE, LazyCustomRTE, LazyCustomRTEIcons, LazyDataVisualization, LazyDigiohForm, LazyFAQ, LazyFAQAndImage, LazyFilters, LazyFiveItemSquares, LazyFormHero, LazyFullHero, LazyFullHeroUI, LazyHubspotForm, LazyHubspotModal, LazyIcon, LazyImageAndQuote, LazyImageAndText, LazyImageAndVideo, LazyImageAndVideoHero, LazyInlineVideo, LazyIntro, LazyLiteHero, LazyLocalZipSearch, LazyNavCollapseable, LazyPagination, LazyPhoneAppDownload, LazyPicture, LazyRelatedArticles, LazyRichTextEditor, LazySamiHowItWorks, LazySamiValueProps, LazyShift, LazySignUp, LazySiteReport, LazySmallCallout, LazySplitFormText, LazySplitHero, LazyStatistic, LazySuperHero, LazySuperHeroTest, LazyTextAndQuote, LazyTrendsHeader, LazyValuePropColumns, LazyVideo, LazyYearReviewHeader, LazyZipSearch, LazyAccordion, LazyBanner, LazyGlobalFooter, LazyGlobalHeader, LazyGlobalSection, LazyPageHead, LazyShiftKeyLogo, LazyShiftKeyLogoWhite, LazyAutoNav, LazyArticle, LazyLocalShiftResults, LazyPage, LazyShiftResults, LazyShiftResultsNull } from '#components'
const lazyGlobalComponents = [
  ["AppHero", LazyAppHero],
["AppHeroSingleImage", LazyAppHeroSingleImage],
["ArticleCard", LazyArticleCard],
["ArticleGrid", LazyArticleGrid],
["ArticleShare", LazyArticleShare],
["Articles", LazyArticles],
["Blockquote", LazyBlockquote],
["BlockquoteModule", LazyBlockquoteModule],
["CTA", LazyCTA],
["CTADropdown", LazyCTADropdown],
["CTAGroup", LazyCTAGroup],
["Callout", LazyCallout],
["Card", LazyCard],
["CardGrid", LazyCardGrid],
["ColumnLayout", LazyColumnLayout],
["CompetitorComparisonChart", LazyCompetitorComparisonChart],
["CookiesList", LazyCookiesList],
["CustomHeading", LazyCustomHeading],
["CustomInlineRTE", LazyCustomInlineRTE],
["CustomRTE", LazyCustomRTE],
["CustomRTEIcons", LazyCustomRTEIcons],
["DataVisualization", LazyDataVisualization],
["DigiohForm", LazyDigiohForm],
["FAQ", LazyFAQ],
["FAQAndImage", LazyFAQAndImage],
["Filters", LazyFilters],
["FiveItemSquares", LazyFiveItemSquares],
["FormHero", LazyFormHero],
["FullHero", LazyFullHero],
["FullHeroUI", LazyFullHeroUI],
["HubspotForm", LazyHubspotForm],
["HubspotModal", LazyHubspotModal],
["Icon", LazyIcon],
["ImageAndQuote", LazyImageAndQuote],
["ImageAndText", LazyImageAndText],
["ImageAndVideo", LazyImageAndVideo],
["ImageAndVideoHero", LazyImageAndVideoHero],
["InlineVideo", LazyInlineVideo],
["Intro", LazyIntro],
["LiteHero", LazyLiteHero],
["LocalZipSearch", LazyLocalZipSearch],
["NavCollapseable", LazyNavCollapseable],
["Pagination", LazyPagination],
["PhoneAppDownload", LazyPhoneAppDownload],
["Picture", LazyPicture],
["RelatedArticles", LazyRelatedArticles],
["RichTextEditor", LazyRichTextEditor],
["SamiHowItWorks", LazySamiHowItWorks],
["SamiValueProps", LazySamiValueProps],
["Shift", LazyShift],
["SignUp", LazySignUp],
["SiteReport", LazySiteReport],
["SmallCallout", LazySmallCallout],
["SplitFormText", LazySplitFormText],
["SplitHero", LazySplitHero],
["Statistic", LazyStatistic],
["SuperHero", LazySuperHero],
["SuperHeroTest", LazySuperHeroTest],
["TextAndQuote", LazyTextAndQuote],
["TrendsHeader", LazyTrendsHeader],
["ValuePropColumns", LazyValuePropColumns],
["Video", LazyVideo],
["YearReviewHeader", LazyYearReviewHeader],
["ZipSearch", LazyZipSearch],
["Accordion", LazyAccordion],
["Banner", LazyBanner],
["GlobalFooter", LazyGlobalFooter],
["GlobalHeader", LazyGlobalHeader],
["GlobalSection", LazyGlobalSection],
["PageHead", LazyPageHead],
["ShiftKeyLogo", LazyShiftKeyLogo],
["ShiftKeyLogoWhite", LazyShiftKeyLogoWhite],
["AutoNav", LazyAutoNav],
["Article", LazyArticle],
["LocalShiftResults", LazyLocalShiftResults],
["Page", LazyPage],
["ShiftResults", LazyShiftResults],
["ShiftResultsNull", LazyShiftResultsNull]
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
